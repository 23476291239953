<template>
  <div v-motion :initial="{
    opacity: 0,
  }" :enter="{

    opacity: 1,
    transition: {
      duration: 500,

      ease: 'easeIn',
    },
  }"
  
   class="flex flex-col py-4">
    <div v-if="product">
      <div class="w-[100%]  md:w-[97%] xl:w-[1280px] lg:w-[90%] mx-auto md:space-y-4 xl:px-4">
        <div
          class="lg:mt-[32px] xl:mt-[32px]  mt-0 text-start lg:p-0 xl:p-0 p-3 "
        >
          <h2
            class="font-NotoSansKhmer lg:ml-0 md:ml-3 text-primery1 font-bold xl:text-heading2 lg:text-heading2 md:text-[20px] text-[18px] xl:text-start lg:text-start md:text-start text-center"
          >
            {{ product.name }}
          </h2>
        </div>

        <div class="lg:mt-[32px] xl:mt-[32px]  mt-0 text-start lg:p-0 xl:p-0 p-3 ">
                    <video v-if="product.video" controls
                        class="border-double border-4 border-indigo-600 rounded-md w-full md:w-[50%] md:h-[400px]  object-cover ">
                        <source :src="product.video" class="h-[30px] " type="video/mp4">
                    </video>

                </div>

        <div
          class="w-full  md:mx-0 lg:ml-0 max-w-[1420px] py-1 lg:p-0 xl:p-0 p-3 md:ml-3 xl:flex lg:flex md:flex block gap-8 space-y-2 xl:space-y-0 lg:space-y-0 md:space-y-0"
        >
          <!-- <div class="xl:w-1/2 lg:w-1/2 md:w-1/2 block">
            <img :src="product.image" alt="" class="rounded-[6px]" />
          </div> -->
          <div class="xl:w-[1280px]  block">
            <span
              v-html=" product.descritpts"
              class="prose text-textbody px-2 md:px-0 md:-mt-5 lg:-mt-0 xl:text-body lg:text-[16px] md:text-[16px] text-[14px]"
            >
             
            </span>
          </div>
        </div>

        <!-- computer ipad-->
        <div
          v-for="(productfeatures, index) in product.feature"
          :key="index"
          class="w-full max-w-[1280px] py-4 lg:p-0 xl:p-0 p-3 md:p-3 space-y-4 xl:mt-[48px] xl:block lg:block md:block hidden "
        >
          <!-- details1 -->

          <div
            :class="{
              'w-full lg:flex lg:flex-row   xl:flex xl:flex-row md:flex   flex flex-col-reverse items-start justify-center  space-y-4 xl:gap-12 lg:gap-12 md:gap-12 gap-5 py-3':
                index % 2 === 0,
              'w-full lg:flex lg:flex-row-reverse xl:flex-row-reverse xl:flex md:flex  flex flex-col-reverse items-start justify-center space-y-4 xl:gap-12 lg:gap-12 md:gap-12 gap-5 py-3':
                index % 2 !== 0,
            }"
          >
            <div
              class="lg:w-1/2 xl:w-full md:w-[95%] md:mx-auto w-full mt-[2px]"
            >
              <h2 v-html="productfeatures.title "
                class="text-primery1 md:mb-2 font-bold font-NotoSansKhmer text-heading3 xl:text-start lg:text-start md:text-start text-center"
              >
                
              </h2>
              <div class="flex  items-center justify-start w-full ">
                <div
                  class="text-textbody xl:text-[16px] lg:text-[16px] md:text-body text-[14px] text-start font-[500]"
                >
                  <!-- {{ productfeatures.details }} -->
                  <span class="text-textbody  xl:text-[16px] lg:text-[16px] md:text-body text-[14px] text-start font-[500] prose " v-html="productfeatures.details"></span>

                </div>
              </div>
            </div>
            <div class="lg:w-1/2 xl:w-full md:w-[95%] w-full md:mx-auto">
              <!-- <img class="md:w-[896px] object-contain md:h-[400px] lg:w-[516px] lg:h-[300px] xl:w-[660px] xl:h-[400px] rounded-[6px]  " :src="productfeatures.image" /> -->
              <img class="md:w-[896px] object-contain md:h-auto lg:w-[516px] lg:h-auto xl:w-[660px] xl:h-auto rounded-[6px]  " :src="productfeatures.image" />

            </div>
          </div>
        </div>
        <!-- mobile  -->
        <div
          v-for="(productfeatures, index) in product.feature"
          :key="index"
          class="w-full lg:hidden md:hidden block xl:hidden p-4"
        >
          <!-- details1 -->

          <div class="w-full mx-auto">
            <div class="w-full">
              <h2
                class="text-primery1 -mt-5 font-NotoSansKhmer text-[18px]  font-semibold text-center"
              >
                {{ productfeatures.title }}
              </h2>
              <div>
                <div class="text-textbody text-[14px] text-start font-[500]">
                  <!-- <span class="prose"> {{ productfeatures.details }}</span> -->
                  <span class="text-textbody text-[14px] text-start font-[500] prose" v-html="productfeatures.details"></span>
                </div>
              </div>
            </div>
            <div class="w-full mt-8">
              <img class="w-full rounded-[6px]" :src="productfeatures.image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-[48px]"></div>
  </div>
  <div style="background-color:rgba(144, 144, 144, 0.15)"
    class="w-full max-w-full  h-auto py-12 flex flex-col items-center justify-center"
  >
    <div class="lg:w-[1024px] xl:w-full md:w-full w-full h-auto">
      <div class=" lg:w-[1024px] xl:w-[1280px] w-full md:w-full mx-auto xl:px-4">
        <div class="lg=:-mt-[1rem] md:-mt-[2rem] text-center">
          <h2
            class="font-NotoSansKhmer font-bold -mt-4 text-primery1 md:mt-3  text-heading4 md:text-[26px]  lg:text-Heading1 xl:text-[24px]"
          >
          អ្នកអាចសួរសំណួរនៅទីនេះ
          </h2>
        </div>
        <form
          @submit.prevent="handleSubmit"
          class="w-[90%]  xl:w-[100%] lg:w-[95%] md:w-[90%] mx-auto mt-5 lg:mt-10 space-y-2"
        >
          <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:gap-8 lg:gap-5">

            <div class="space-y-2">
            <label required for="name" class="text-body font-[500]"
              >ឈ្មោះ<span class="text-red-500"> *</span></label
            >
            <input
              required
              class="input1"
              name="name"
              type="text"
              placeholder="ឈ្មោះរបស់អ្នក"
              v-model="name"
            />
          </div>
          <div class="space-y-2 mt-2 lg:mt-0">
            <label for="name" class="text-body font-[500]"
              >ឈ្មោះក្រុមហ៊ុន <span class="text-red-500">*</span></label
            >
            <input
              required
              class="input1"
              name="name"
              type="text"
              placeholder="ឈ្មោះក្រុមហ៊ុន"
              v-model="companyname"
            />
          </div>

          </div>
  
          <div
            class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:gap-8 lg:gap-5"
          >
            <div class="space-y-2">
              <label for="name" class="text-body font-[500]"
                >លេខទូរស័ព្ទ <span class="text-red-500">*</span></label
              >
              <input
                required
                class="input1"
                name="name"
                type="text"
                placeholder="លេខទូរស័ព្ទ"
                v-model="number"
              />
            </div>
            <div class="space-y-2">
              <label for="name" class="text-body font-[500]">តេលេក្រាម</label>
              <input
                class="input1"
                name="name"
                type="text"
                placeholder="តេលេក្រាម"
                v-model="numbertelete"
              />
            </div>
          </div>
          <div class="space-y-2">
            <label for="name" class="text-body font-[500]">អាស័យដ្ឋាន</label>
            <input
              class="input1"
              name="name"
              type="text"
              placeholder="អាស័យដ្ឋាន"
              v-model="address"
            />
            <input
              required
              class="input hidden"
              name="name"
              type="text"
              placeholder="អាស័យដ្ឋាន"
              v-model="status"
            />
            <div></div>
          </div>
          <label for="name" class="text-body font-[500]"
            >សេវ៉ាកម្ម <span class="text-red-500">*</span></label
          >
          <div class="">
            <select required class="input1 w-full text-[18px] " v-model="itemsType">
              <!-- Add the "Test" option as the first option -->
              <!-- <option value="Test"></option> -->

         
              <!-- <option v-for="option in category" :key="option">
                {{ option.name }}
              </option> -->
              <option disabled selected value class="placeholder-option">--ជ្រើសរើសសេវ៉ាកម្ម--</option>
                <!-- Loop through the data array for other options -->
                <option v-for="option in category" :key="option.id" :value="option.name" class="option-item">
                  {{ option.name }}
                </option>
            </select>
          </div>
          <div class="space-y-2">
            <label for="គោលបំណង" class="text-body font-[500]"
              >គោលបំណង <span class="text-red-500">*</span></label
            >
            <textarea
              required
              class="input1"
              placeholder="Purpose"
              name=""
              id=""
              cols="30"
              rows="5"
              v-model="pospurse"
            ></textarea>
          </div>
          <div class="flex w-full">
            <button
              class="md:w-[84px] md:h-[50px] h-[46px] rounded-full md:rounded-md box-btn text-borderBody xl:px-32 lg:px-32 md:px-20 w-full xl:w-[10rem] flex items-center justify-center lg:w-[10rem] py-4 mt-4"
            >
              ផ្ញើរ
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Notivue v-slot="item">
    <Notification :item="item" />
  </Notivue>
</template>

<script>
import { push, Notivue, Notification } from "notivue";

import FooterVue from "./Footer.vue";
import NavbarVue from "./Navbar.vue";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { projectFirestore } from "@/firebase/config";
import { doc, getDoc } from "firebase/firestore";
import useCollection from "@/composible/useCollection";
import { getCollectionQuery } from "@/composible/getCollection";
import { timestamp } from "@/firebase/config";
import { useRoute } from 'vue-router';
export default {
  props: {
    id: String,
  },
  components: {
    FooterVue,
    NavbarVue,
    Notivue,
    Notification,
  },
  setup(props) {
    const product = ref(null);

    const router = useRoute();

    const fetchProduct = async () => {
      try {
        const productRef = doc(projectFirestore, "products", router.params.id);
        const docSnap = await getDoc(productRef);
        if (docSnap.exists()) {
          product.value = docSnap.data();
        } else {
          console.error(`Product with ID ${props.id} not found.`);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    onMounted(() => {
      fetchProduct();
    });
    const status = ref("Pending");
    const category = ref([]);
    const itemsType = ref("");
    const name = ref("");
    const companyname = ref("");
    const number = ref("");
    const numbertelete = ref("");
    const address = ref("");
    const pospurse = ref("");

    const { addDocs } = useCollection("inbox");
    const handleSubmit = async () => {
      try {
        const productData = {
          status: status.value,
          name: name.value,
          companyname: companyname.value,
          numbertelete: numbertelete.value,
          itemsType: itemsType.value,
          pospurse: pospurse.value,
          createdAt: timestamp(),
        };

        // Add 'number' field if it has a value
        if (number.value.trim() !== "") {
          productData.number = number.value;
        }

        // Add 'address' field if it has a value
        if (address.value.trim() !== "") {
          productData.address = address.value;
        }

        await addDocs(productData);
        handleClear();
        push.success("អរគុណសម្រាប់ការទាក់ទង");

        console.log("Product operation successful");
      } catch (error) {
        console.error("Error performing product operation:", error);
      }
    };

    const getDataCategory = async () => {
      try {
        await getCollectionQuery(
          "categories",
          [],
          (data) => {
            category.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    onMounted(() => {
      getDataCategory();
    });
    const handleClear = () => {
      status.value = "";
      name.value = "";
      companyname.value = "";
      number.value = "";
      numbertelete.value = "";
      address.value = "";
      pospurse.value = "";
      itemsType.value = "";
    };
    return {
      product,
      itemsType,
      category,
      name,
      companyname,
      number,
      numbertelete,
      address,
      pospurse,
      status,
      handleSubmit,
    };
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
p {
  line-height: 2;
}
</style>
