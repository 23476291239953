<template>
  <!-- <div
  
    @click="handleClose"
    class="w-full h-full bg-black/30 fixed lg:top-[12rem] md:top-[12rem] top-[65px]  right-0"
  > -->
  <div

  @click="handleClose" 
  class="w-full h-full bg-black/20 fixed lg:top-[8rem] md:top-[129px] top-[69px] xl:hidden right-0  "
>
    <div
     style="box-shadow: 0 20px 9px -22px rgba(0,0,0,0.2) inset;"
      class="text-start font-NotoSansKhmer flex flex-col w-[304px] h-screen items-start -ml-2  gap-[20px] md:gap-[40px] pl-[3rem] md:w-[587px] pt-[30px] bg-background "
    >
      <router-link
        @click="handleClose"
        to="/"
        class="text-heading4 text-primery1 font-bold hover:text-primery1/70 duration-300"
      >
        ទំព័រដើម
      </router-link>
     
      <router-link
        @click="handleClose"
        to="/productsandservices"
        class="text-heading4 text-primery1 font-bold hover:text-primery1/70 duration-300"
      >
      ផលិតផលនិងសេវ៉ាកម្ម
      </router-link>
      <router-link
        @click="handleClose"
        to="/product"
        class="text-heading4 text-primery1 font-bold hover:text-primery1/70 duration-300"
      >
      ឧបករណ៍
      </router-link>
      <router-link
        @click="handleClose"
        to="/aboutUs"
        class="text-heading4 text-primery1 font-bold hover:text-primery1/70 duration-300"
      >
      អំពីពួកយើង
      </router-link>
      <router-link
        @click="handleClose"
        to="/contact"
        class="text-heading4 text-primery1 font-bold hover:text-primery1/70 duration-300"
      >
      ទំនាក់ទំនង
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("close");
      // props.handleCloseMenu;
      
    };
    return { handleClose };
  },
};
</script>

<style scoped>
/*.router-link-active {
  transition: 0.3s;
  border-bottom: 3px #500192 solid;
  display: inline-block;
}*/


</style>
