
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
const firebaseConfig = {
    apiKey: "AIzaSyCUbGqFbBZb-kPjU_3fTkYIcAfKUwEvbuw",
    authDomain: "master-it-system.firebaseapp.com",
    projectId: "master-it-system",
    storageBucket: "master-it-system.appspot.com",
    messagingSenderId: "435598007408",
    appId: "1:435598007408:web:3bfe4413ca5bbffbe168b5",
    measurementId: "G-E2Q56CHZM0"
};
const firebaseApp = initializeApp(firebaseConfig);
const projectFirestore = getFirestore(firebaseApp);
const projectAuth = getAuth(firebaseApp);
const projectStorage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, "asia-southeast1");
const timestamp = serverTimestamp;
export { projectAuth, projectFirestore, projectStorage, timestamp, functions };