import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/admin/Dashboard.vue'
import ServiceAndProduct from '@/admin/Categories.vue'
import Product from '@/admin/Product.vue'
import Partner from '@/admin/Partner.vue'
import AnswerAndQuestion from '@/admin/AnswerAndQuestion.vue'
import AdminMangement from "../admin/AdminMangement.vue"
import Login from '@/admin/Login.vue'
import Register from '@/admin/Register.vue'
import FogetPassword from '../admin/FogetPassword.vue'
import Profileinfo from '@/admin/Profileinfo.vue'
import Inbox from '@/admin/Inbox.vue'
import ProductDetail from '@/admin/ProductDetail.vue'
import InboxDetails from '../admin/InboxDetails.vue'
import { projectAuth } from '@/firebase/config'
import ListProduct from '@/admin/ListProduct.vue'
import TestLayout from '../admin/TestLayout.vue'
import BussinessServices from '@/admin/BussinessServices.vue'


// Function to check if user is authenticated
const requireAuth = (to, from, next) => {
    const user = projectAuth.currentUser;
    if (user) {
        next(); // Proceed to the route
    } else {
        next({ name: "login" }); // Redirect to login page
    }
};

// Function to check if user is already logged in
const checkIfAlreadyLogin = (to, from, next) => {
    const user = projectAuth.currentUser;
    if (user) {
        next("/admin"); // Redirect to dashboard if already logged in
    } else {
        next(); // Proceed to the route
    }
};

const routes = [
    // Public routes
    {
        path: '/',
        name: 'login',
        component: Login,
        beforeEnter: checkIfAlreadyLogin,
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/forgetpassword',
        name: 'forgetpassword',
        component: FogetPassword
    },
    // Protected routes
    {
        path: '/admin',
        name: 'admin',
        component: Dashboard,
        beforeEnter: requireAuth,
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profileinfo,
        beforeEnter: requireAuth,
    },
    {
        path: '/services',
        name: 'servicesd',
        component: ServiceAndProduct,
        beforeEnter: requireAuth,
    },
    {
        path: '/products',
        name: 'products',
        component: Product,
        beforeEnter: requireAuth,
    },
    {
        path: '/products/:id',
        name: 'productsdetails',
        component: ProductDetail,
        props: true,
        beforeEnter: requireAuth,
    },
    {
        path: '/partner',
        name: 'partner',
        component: Partner,
        beforeEnter: requireAuth,
    },
    {
        path: '/qanda',
        name: 'qanda',
        component: AnswerAndQuestion,
        beforeEnter: requireAuth,
    },
    {
        path: '/adminmanager',
        name: 'adminmanager',
        component: AdminMangement,
        beforeEnter: requireAuth,
    },
    {
        path: '/inbox',
        name: 'inbox',
        component: Inbox,
        beforeEnter: requireAuth,
    },
    {
        path: '/inbox/:id',
        name: 'inboxdetails',
        component: InboxDetails,
        props: true,
        beforeEnter: requireAuth,
    },
    {
        path: "/listproduct",
        name: "listproduct",
        component: ListProduct,
        beforeEnter: requireAuth
    },
    {
        path: "/bussiness",
        name: "bussiness",
        component: BussinessServices,
        beforeEnter: requireAuth
    },
    {
        path: "/bussinessDetail",
        name: "/bussinessDetail"
    },
    {
        path: "/testLayout",
        name: "testLayout",
        component: TestLayout,
        // beforeEnter: requireAuth
    }
];

const adminrouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, behavior: 'smooth' };
        }
    }
});


export default adminrouter
