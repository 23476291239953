<template>

    <div class="my-[20px]">
        <h1 class="sm:text-[25px] md:text-[28px] text-center text-[20px] lg:text-[24px] xl:text-[28px]">ផលិតផលលេចធ្លោ
        </h1>
    </div>

    <div class="mb-[3rem]">
        <div class="md:w-[100%] lg:w-[85%] xl:w-[1280px]   xl:mx-auto mx-auto  hidden sm:block">
            <swiper :slides-per-view="1" :space-between="30" :loop="loop" :navigation="{
                nextEl: '.button-next-slide',
                prevEl: '.button-prev-slide'
            }" :keyboard="true" :pagination="pagination" :modules="modules" class="mySwiper" :effect="'fade'"
                :speed="800" @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide
                    class="fade-slide  p-1 h-[392px] sm:h-[300px]  md:h-[290px] lg:h-[302px] xl:h-[310px]  bg-white"
                    v-for="(image, index) in images" :key="index">
                    <div class="flex sm:gap-5 sm:mx-5 md:gap-[30px] md:pl-4 lg:p-0 lg:gap-[24px] gap-10 xl:gap-[30px]">
                        <div
                            class="sm:w-[240px] sm:h-[170px] md:w-[392px] md:h-[230px] lg:w-[492px] lg:h-[220px] xl:w-[540px] ">
                            <img class="bg-white sm:w-[300px]  w-[360px] h-[220px] md:w-[440px] md:h-[220px] xl:w-[440px] xl:h-[260px] lg:w-[392px] lg:h-[220px]  object-contain  rounded-[6px] "
                                :src="image.image" alt="">
                        </div>
                        <div
                            class="sm:w-[400px] md:ml-5 md:w-[464px] lg:w-[700px] lg:h-[220px] xl:w-[900px] xl:h-[260px]">
                            <div
                                class="sm:space-y-2 md:space-y-[16px] md:p-[
                                            20px, 10px, 20px, 10px] lg:py-0 xl:p-[20px, 10px, 20px, 10px] lg:space-y-[10px] xl:space-y-[16px]">
                                <h3 class="md:text-[16px] lg:text-[20px] xl:text-[24px]  font-bold ">{{ image.name }}
                                </h3>
                                <div v-html="image.descritpts"
                                    class="prose prose-p:line-clamp-8 sm:text-[12px] md:text-[14px] lg:text-[14px] xl:text-[16px] xl:text-wrap font-medium line-clamp-6">
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </swiper-slide>
            </swiper>
        </div>


        <!-- mobile Size -->
        <div class="sm:hidden">
            <div v-if="products && products.length > 0"
                class="relative rounded-[6px]  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mb-2 m-[16px] h-full "
                ref="carouselContainer" @scroll="handleScroll">
                <!-- Previous Button -->
                <div @click="handlePrevious" :class="{ hideButton: start === 0 }"
                    class="absolute  top-[12rem] -left-1 z-50 transform ">
                    <div
                        class="w-[40px] h-[40px] rounded-full  bg-[#500192]/30 shadow-md text-white flex hover:bg-indigo-300 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                    </div>
                </div>
                <!-- Next Button -->
                <div v-if="windowWidth < 640" @click="handleNext" :class="{ hideButton: end >= products.length - 1 }"
                    class="absolute  top-[12rem] -right-1 z-10 transform ">
                    <div
                        class="w-[40px] h-[40px] rounded-full bg-[#500192]/30 shadow-md text-white flex hover:bg-indigo-300 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </div>
                </div>
                <!-- Products -->
                <Transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                    <div v-show="currentIndex >= start && currentIndex <= end" :key="currentIndex"
                        class="relative transform transition-transform duration-300 flex justify-center">
                        <div v-for="(product, index) in products" :key="product.id" v-show="index === currentIndex">
                            <div class="">
                                <img :src="product.image"
                                    class="w-full object-contain h-[220px] rounded-[6px]   duration-1000"
                                    :style="{ opacity: 1 }" />
                            </div>
                            <div class="text-center space-y-[22px]">
                                <h1 class="text-[18px] mt-[22px]">{{ product.name }}</h1>
                                <span v-html="product.descritpts" class="line-clamp-3  text-[16px] text-justify "></span>
                            </div>
                        </div>
                    </div>
                </Transition>
                <!-- Indicators -->
                <div class="mt-[30px] justify-center  transform flex space-x-2 z-20">
                    <span v-for="(product, index) in products" :key="product.id"
                        :class="[' rounded-full h-[10px] w-[10px]', { 'bg-[#500192] transition-all duration-300 ease-in w-[15px] h-[10px]': index === currentIndex, ' w-[10px] h-[10px] bg-[#D9D9D9]': index !== currentIndex }]"
                        @click="goToSlide(index)" class="cursor-pointer"></span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { ref, onMounted, onUnmounted, watch, watchEffect } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Pagination, Navigation, EffectFade } from 'swiper/modules';
import getCollections from '@/composible/getCollections';

import { projectFirestore } from '@/firebase/config';
import { collection, query, where, getDocs } from '@firebase/firestore';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const loop = ref(true);
        const images = ref(null)
        const products = ref(null)
        // const images = [
        //     "https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg",
        //     "https://castfromclay.co.uk/wp-content/uploads/image-asset-1-1024x683.jpeg",
        //     "https://media.istockphoto.com/id/864516870/photo/young-woman-photographing-the-autumn-season.jpg?s=612x612&w=0&k=20&c=M3G2SwKJ15zolsSaFABsneLitdWXCrrJ3LkTEKnIOys=",
        //     "https://gratisography.com/wp-content/uploads/2024/01/gratisography-cyber-kitty-800x525.jpg",
        //     "https://images.unsplash.com/photo-1575936123452-b67c3203c357?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        // ];

        const swiperInstance = ref(null);
        const showPrevButton = ref(true);
        const showNextButton = ref(true);
        const isSmallScreen = ref(window.innerWidth <= 768);

        // const { documents: images } = getCollections("products");


        onMounted(async () => {
            try {
                const q = query(collection(projectFirestore, "products"), where("productdisplay", "==", "outstanding"));

                const querySnapshot = await getDocs(q);
                const result = []
                querySnapshot.forEach((doc) => {

                    result.push(doc.data());
                });

                images.value = result
            }
            catch (err) {
                console.log(err)
            }
        })


        const onSwiper = (swiper) => {
            swiperInstance.value = swiper;
        };

        const onSlideChange = () => {
            const swiper = swiperInstance.value;
            if (swiper) {
                const { isBeginning, isEnd } = swiper;
                showPrevButton.value = !isBeginning;
                showNextButton.value = !isEnd;
            }
        };

        const updateScreenSize = () => {
            isSmallScreen.value = window.innerWidth <= 768;
            loop.value = !isSmallScreen.value;
        };

        onMounted(() => {
            window.addEventListener('resize', updateScreenSize);
            updateScreenSize();
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateScreenSize);
        });

        watch(() => window.innerWidth, updateScreenSize);



        //mobile Size

        const start = ref(0);
        const end = ref(1);
        const windowWidth = ref(window.innerWidth);
        const currentIndex = ref(0);
        const carouselContainer = ref(null);
        const increment = ref(1);
        const decrement = ref(1);

        //const { error, documents: products } = getCollections("products");

        onMounted(async () => {
            try {
                const q = query(collection(projectFirestore, "products"), where("productdisplay", "==", "outstanding"));

                const querySnapshot = await getDocs(q);
                const result = []
                querySnapshot.forEach((doc) => {

                    result.push(doc.data());
                });

                products.value = result
            }
            catch (err) {
                console.log(err)
            }
        })

        if (!products.value) products.value = [];

        const onResize = () => {
            windowWidth.value = window.innerWidth;
            updateEnd();
            if (currentIndex.value < start.value || currentIndex.value > end.value) {
                currentIndex.value = start.value;
            }
            updateScroll();
        };

        onMounted(() => {
            window.addEventListener('resize', onResize);
            updateEnd();
            if (carouselContainer.value) {
                carouselContainer.value.addEventListener('scroll', handleScroll);
            }
        });

        onUnmounted(() => {
            if (carouselContainer.value) {
                carouselContainer.value.removeEventListener('scroll', handleScroll);
            }
            window.removeEventListener('resize', onResize);
        });

        const updateEnd = () => {
            if (windowWidth.value < 640) {
                end.value = start.value;
            } else if (windowWidth.value < 768) {
                end.value = start.value + 1;
            } else if (windowWidth.value < 1024) {
                end.value = start.value + 2;
            } else if (windowWidth.value < 1280) {
                end.value = start.value + 3;
            } else {
                end.value = start.value + 4;
            }
        };

        watchEffect(updateEnd);

        const handleNext = () => {
            if (end.value < products.value.length - 1) {
                start.value += increment.value;
                end.value += increment.value;
                currentIndex.value = end.value;
                updateScroll();
            }
        };

        const handlePrevious = () => {
            if (start.value > 0) {
                start.value -= decrement.value;
                end.value -= decrement.value;
                currentIndex.value = start.value;
                updateScroll();
            }
        };

        const updateScroll = () => {
            if (carouselContainer.value) {
                carouselContainer.value.scrollTo({
                    left: carouselContainer.value.scrollWidth * (currentIndex.value / products.value.length),
                    behavior: 'smooth'
                });
            }
        };



        const beforeEnter = (el) => {
            el.style.opacity = 0;
        };

        const enter = (el, done) => {
            el.offsetHeight;
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 1;
            done();
        };

        const leave = (el, done) => {
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 0;
            done();
        };

        const goToSlide = (index) => {
            currentIndex.value = index;
            start.value = index;
            end.value = index + (windowWidth.value < 640 ? 0 : 1);
            updateScroll();
        };


        return {
            pagination: {
                clickable: true,
                renderBullet: function (index, className) {
                    const isActive = className.includes('swiper-pagination-bullet-active');
                    const activeClass = isActive ? 'bg-primray1 w-[10px] h-[10px]' : 'bg-primery1 w-[15px] rounded-0 h-[15px]';
                    return `<span class="${className} ${activeClass} "></span>`;
                }
            },
            modules: [Pagination, Navigation, EffectFade],
            images,
            loop,
            onSwiper,
            onSlideChange,
            showPrevButton,
            showNextButton,
            isSmallScreen,
            products, start, end, windowWidth, handleNext, handlePrevious, currentIndex, beforeEnter, enter, leave, carouselContainer, goToSlide
        };
    },
};
</script>

<style scoped>
.swiper-pagination-bullet {
    background-color: #500192 !important;
    width: 16px;
    height: 16px;
    margin-bottom: -15px !important;
    margin: 5px !important;
}

.swiper-pagination-bullet-active {
    width: 28px !important;
    height: 16px !important;
    border-radius: 20px !important;
    transition: all 0.5s;
    margin-bottom: -15px !important;
    margin: 5px !important;
}

.swiper-slide {
    opacity: 0;
    transition: opacity 1s ease;
}

.swiper-slide-active {
    opacity: 1;
}

.button-prev-slide,
.button-next-slide {
    display: none;
}

@media (max-width: 768px) {

    .button-prev-slide,
    .button-next-slide {
        display: block;
    }
}

@media (max-width: 1280px) {

    .swiper-pagination-bullet-active {
        width: 15px !important;
        height: 10px !important;
        border-radius: 20px !important;
        transition: all 0.5s;
        margin-bottom: -15px !important;
        margin: 5px !important;
    }

    .swiper-pagination-bullet {
        background-color: #500192 !important;
        width: 10px;
        height: 10px;
        margin-bottom: -15px !important;
        margin: 5px !important;
    }


}

.hideButton {
    display: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.actives {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #D9D9D9;
}
</style>
