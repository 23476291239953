<template>
    <div>
        <div>
            <h1
                class="text-center xl:text-[28px] xl:my-2 md:text-[28px] md:my-[12px] my-[14px] lg:text-[24px] lg:my-3 text-[20px] xl:mt-10">
                ឧបករណ៍</h1>
        </div>
        <div class="w-[90%] md:w-[90%] lg:w-[90%]  xl:w-full  mx-auto">
           <div class="p-4 xl:w-[1280px] mx-auto">
            <div
                class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-[20px] md:gap-[30px] lg:gap-[24px] xl:gap-[30px] mb-5 w-full">
                <div v-for="pro in product" :key="pro">
                    <div style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;"
                        class="bg-white h-[160px] rounded-[6px] md:h-[230px] lg:h-[200px]  xl:h-[228px] ">
                        <div class="flex p-[16px] gap-6">
                            <img :src="pro.url"
                                class="h-[100px] w-[100px] md:w-[140px] md:h-[140px] lg:w-[120px] lg:h-[120px] xl:w-[180px] xl:h-[180px] object-contain"
                                alt="">
                            <div>
                                <h4 class="font-bold md:text-[18px] xl:text-[18px] lg:text-[15px] text-[16px] line-clamp-2">{{
                    pro.productName }}</h4>
                                <div
                                    v-html="pro.productDesscript"
                                    class="xl:text-[16px] prose mt-[12px] md:text-[16px] xl:mt-[16px]  lg:text-[#909090] lg:text-[12px] line-clamp-2 text-[14px]">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
             <router-link to="/product" class="flex justify-center md:justify-end my-[35px]">
            <button
                class="flex w-[154px] h-[43px] px-6 space-x-[10px] rounded-full justify-between items-center text-white md:px-6 lg:px-4 md:w-[169px] md:h-[44px] xl:px-6 shadow-lg md:rounded-[6px] lg:w-[145px] lg:h-[43px] xl:w-[169px] xl:h-[44px] bg-primery1 hover:bg-primery1/50 transition-all duration-300">
                <span>See more</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>

            </button>
            </router-link>
           </div>
           
        </div>
    </div>
    <!-- {{ product }} -->
</template>

<script>
import getCollection from '@/composible/getCollections'
export default {

    setup() {

        const { documents: product } = getCollection("newProduct")


        return { product }

    }

}
</script>

<style></style>