<template>
  <div class="w-2/5 mx-auto my-10">
    <div id="app">
      <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';  // Import `ref` from Vue
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  components: {
    ckeditor: CKEditor.component  // Register the CKEditor component
  },
  setup() {
    const editor = ClassicEditor; // Set the CKEditor editor type
    const editorData = ref('');  // Initialize the editor data
    const editorConfig = ref({  // You can customize your CKEditor configuration here
      // Add CKEditor configuration options
    });

    return {
      editor,
      editorData,
      editorConfig,
    };
  }
};
</script>
