<template>
  <div v-motion :initial="{
    opacity: 0,
  }" :enter="{

    opacity: 1,
    transition: {
      duration: 500,
      ease: 'easeIn',
    },
  }" class="flex flex-col bg-background px-2 md:px-0">
    <div class="">
      <div class="w-[100%]  md:w-[95%] xl:w-[1280px] lg:w-[90%] mx-auto">
        <div class="lg:mt-8 xl:mt-8 mt-4 text-start lg:p-0 xl:p-0 p-3 ">
          <h1 v-for="categories in category" :key="categories"
            class=" md:text-start -mt-3 md:-mt-0  lg:text-start font-NotoSansKhmer text-primery1  xl:text-start text-center xl:ml-0  xl:text-[30px] font-semibold lg:text-[30px] md:text-[30px] text-heading2_Mobile">
            ប្រព័ន្ធគ្រប់គ្រងអាជីវកម្ម
          </h1>
        </div>
        <div
          class="w-full  xl:w-[1280px]  lg:p-0 xl:p-0 p-3 md:p-3 mx-auto border-b-gray-400 lg:h-auto xl:auto md:h-auto h-auto lg:flex lg:flex-row xl:flex xl:flex-row md:flex md:flex-col-reverse flex flex-col-reverse items-center justify-between">
          <div v-for="categories in category" :key="categories"
            class="text-textbody  w-full font-[500] text-[14px] lg:my-5 indent-0 md:text-[18px] lg:text-[14px] xl:text-[18px] md:indent-0 lg:indent-0 xl:indent-16">
            <!-- {{ categories.description }} -->
            <span
              class="prose-li:text-wrap prose-neutral prose   prose-li:indent-0 text-textbody font-[500] text-[14px]  indent-0 md:text-[18px] lg:text-[14px] xl:text-[18px] md:indent-0  "
              v-html="categories.description"></span>
          </div>
        </div>

        <div class="md:mt-0  lg:mt-5 md:-ml-3 lg:-ml-0 ">
          <!-- <p class="indent-0 text-textbody  text-center md:text-start md:indent-16 text-[14px] md:mx-0 md:text-[20px] lg:text-[14px]  xl:text-heading4   font-NotoSansKhmer lg:space-y-1 mb-3">ម៉ាស្ទ័រអាយធី ស៊ីស្ទិម ផ្គត់ផ្គង់ និងរៀបចំប្រព័ន្ធគ្រប់គ្រងអាជីវកម្ម ដូចជា៖</p> -->
          <ul
            class="pl-4 text-textbody tex lg:pl-0 text-[14px] md:mx-0 md:text-[20px] lg:text-[14px]  xl:text-heading4   font-NotoSansKhmer lg:space-y-1 mb-5 ">


            <!-- <li>១. ប្រព័ន្ធគ្រប់គ្រងការលក់ (POS System)</li>
            <li>២. ប្រព័ន្ធគ្រប់គ្រងសាលារៀន (School Management System)</li>
            <li>៣. ប្រព័ន្ធគ្រប់គ្រងកម្ចី និងបង់រំលស់ (Loan Management System)</li>
            <li>៤. ប្រព័ន្ធគ្រប់គ្រងផ្នែកធនធានមនុស្ស និងកត់អវត្តមាន (HR Management System)</li>
            <li>៥. ប្រព័ន្ធគ្រប់គ្រងផ្ទះសំណាក់ និងបន្ទប់ជួល (Room Rental and Hotel Management System)</li>
            <li>៦​​. ប្រព័ន្ធគ្រប់គ្រងបញ្ញើឥវ៉ាន់ (Delivery Management System)</li>
            <li>៧. ប្រព័ន្ធគ្រប់គ្រងគណនេយ្យ (Accounting System)</li>
            <li>៨. និងប្រព័ន្ធគ្រប់គ្រងអាជីវកម្មផ្សេងៗទៀត (Other Systems)</li> -->
          </ul>
        </div>


        <div
          class="lg:w-[1024px] xl:w-[1420px] lg:p-0 xl:p-0  md:p-3 mx-auto border-b-gray-400 lg:h-auto xl:auto md:h-auto h-auto">

        </div>

        <div class="w-full max-w-[1420px] py-4 lg:p-0 xl:p-0 p-3 md:p-3 p">
          <div class="w-full lg:columns-3 xl:columns-3 md:columns-2 columns-1 space-y-4 py-4">
            <div v-for="products in dataitem" :key="products" class="group relative w-full">
              <div
                class="absolute lg:block xl:block md:block hidden h-full w-full overflow-hidden rounded-md group-hover:bg-opacity-55">
                <div
                  class="absolute top-0 h-10 w-10 overflow-auto bg-opacity-0 group-hover:h-full group-hover:w-full transition-all duration-300 group-hover:bg-white/50">
                </div>

                <div class="flex justify-center items-end w-full h-[40%]">
                  <div
                    class="text-center text-2xl font-semibold text-black overflow-hidden opacity-0 group-hover:opacity-100">
                    <p class=" group-hover:translate-y-0 duration-300">
                      {{ products.name }}

                    </p>
                  </div>
                </div>
                <router-link v-if="products && products.id" :to="{
    name: 'systemdetails',
    params: { id: products.id },
  }" class="flex items-end justify-center group-hover:h-[100px] opacity-0 group-hover:opacity-100">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor"
                    class="h-12 w-12 cursor-pointer text-black opacity-0 duration-300 group-hover:translate-y-[0px] group-hover:opacity-100">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </router-link>
              </div>
              <router-link :to="{
    name: 'systemdetails',
    params: { id: products.id },
  }"
                class="absolute lg:hidden xl:hidden md:hidden group py-3 group-active:bg-primery1/80 cursor-pointer bg-primery1 bottom-0 w-full bg-opacity-50 rounded-bl-[6px] rounded-br-[6px] flex items-center justify-end">
                <div class="w-full relative">
                  <h2 class="text-body text-center text-background">
                    {{ products.name }}

                  </h2>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-circle-chevron-right w-8 h-8 text-white absolute top-0 right-3  duration-300">
                    <circle cx="12" cy="12" r="10" />
                    <path d="m10 8 4 4-4 4" />
                  </svg>
                </div>
              </router-link>
              <!-- <div class="w-full h-full">
                <img class="w-full rounded-md" :src="products.image" />
              </div> -->
              <div class="w-full h-full bg-primery1/5 rounded-md">
                <img class="w-full object-scale-down rounded-md   " :src="products.image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mt-5">
      <img
        class="w-[95%] h-full md:w-[93%] md:h-[300px] xl:w-[1280px] xl:h-[583px] lg:w-[91%]  object-contain lg:h-[432px] mx-auto object-center"
        src="../assets/image/images1.jpg" alt="" />
    </div>
    <div class="w-full h-[48px]"></div>
  </div>
</template>

<script>
import { getCollectionQuery } from "@/composible/getCollection";
import { ref, onMounted } from "vue";
export default {
  setup() {
    const dataitem = ref([]);
    const category = ref([]);
    const getData = async () => {
      try {
        await getCollectionQuery(
          "products",
          [],
          (data) => {
            dataitem.value = data.filter(
              (item) => item.type === "Business Management System"
            );
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    const getDataCategory = async () => {
      try {
        await getCollectionQuery(
          "categories",
          [],
          (data) => {
            category.value = data.filter(
              (item) => item.route === "BusinessManagementSystem"
            );
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    onMounted(() => {
      getData();
      getDataCategory();
    });
    return { dataitem, category };
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
</style>
