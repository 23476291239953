<template>
  <div v-motion :initial="{
    opacity: 0,
  }" :enter="{

    opacity: 1,
    transition: {
      duration: 500,

      ease: 'easeIn',
    },
  }" class="flex flex-col bg-background px-2 md:px-0">
    <div class="w-full md:w-[90%] xl:w-[1280px] lg:w-[90%] mx-auto space-y-4 relative">
      <div class=" lg:mt-8 xl:mt-8 md:mt-3 mt-4 text-start lg:p-0 xl:p-0 xl:px-4">
        <h2
          class="text-[20px] md:mt-8 md:text-[30px] md:text-start  lg:text-[22px] xl:text-[30px] font-NotoSansKhmer text-primery1 lg:text-start xl:text-start text-center xl:ml-0   font-semibold  ">
          អភិវឌ្ឍន៍ប្រព័ន្ធ
        </h2>
      </div>

      <div
        class=" w-full xl:w-[1280px] xl:px-4 md:py-0 lg:p-0 xl:p-0  mx-auto border-b-gray-400 lg:h-auto xl:auto md:h-auto h-auto lg:flex lg:flex-row xl:flex xl:flex-row md:flex md:flex-col-reverse flex flex-col-reverse items-center justify-between">
        <div class="flex ">
          <div v-for="categories in category" :key="categories"
            class="text-[14px] mx-4  md:mx-0 text-textbody md:text-[18px] lg:text-[14px] xl:text-[18px] font-[500]">
            <span
              class="prose prose-li:text-wrap sm:prose-li:text-wrap text-[14px]  mx-4  md:mx-0 text-textbody md:text-[18px] lg:text-[14px] xl:text-[18px] font-[500]"
              v-html="categories.description"></span>
          </div>

        </div>
      </div>
      <div
        class=" absolute sm:top-[17%] md:top-[18%] md:left-5 lg:left-0 lg:top-[19%] xl:top-[18%] w-full  xl:h-[300px] xl:px-4  xl:w-[1280px] py-4 lg:p-0 xl:p-0 p-3 md:p-3 mx-auto border-b-gray-400 lg:h-[204px]  md:h-auto h-auto lg:flex lg:flex-row xl:flex xl:flex-row    md:flex items-start justify-between">
        <div class="md:hidden  lg:py-0 xl:py-0 md:py-2 py-2 mt-0 flex justify-center">
          <img src="../assets/system/cover.png" class="w-[300px] h-[329px]" alt="" />
        </div>
        <div class="md:mt-0  lg:mt-5 md:-ml-3 lg:-ml-0 ">
          <ul
            class=" md:mx-0 text-[14px] md:text-[20px] lg:text-[14px]  xl:text-heading4   font-NotoSansKhmer lg:space-y-1 ">
            <!-- <li class="">១. ការរៀបចំផែនការ (ប្រមូលទិន្នន័យ)</li>
            <li>២. ការវិភាគ (វិភាគទៅលើទំហំការងារ រយៈពេល)</li>
            <li>
              ៣. ការរចនា (រចនាលំនាំកម្មវិធី ដែលអាចដឹងថាកម្មវិធីនោះមានរូបរាង
              និងដំណើរការបែបណា)
            </li>
            <li>៤. ការសរសេរកូដ (បម្លែងដំណាក់កាលទីបីទៅជាផ្លូវការ)</li>
            <li>៥. សាកល្បងប្រើប្រាស់ ដើម្បីស្វែងរកកំហុសឆ្គង និងកែលម្អ</li>
            <li>៦. ការថែទាំទៅលើប្រព័ន្ធ</li>
            <li>lore</li> -->
            <!-- <div  v-for="categories in category" :key="categories"
              class="text-[14px] w-[800px] mx-4  md:mx-0 text-textbody md:text-[18px] lg:text-[14px] xl:text-[18px] font-[500]">
              <span
                class="prose text-[14px] mx-4  md:mx-0 text-textbody md:text-[18px] lg:text-[14px] xl:text-[18px] font-[500]"
                v-html="categories.description"></span>
            </div> -->

          </ul>
        </div>
        <div class="hidden md:block lg:py-0 xl:py-0 md:py-2 py-2">
          <img src="../assets/system/cover.png"
            class="md:w-[200px] object-contain md:-mt-10 lg:mt-0 lg:w-[185px] lg:h-[204px]  xl:w-[267px] xl:h-[294px]  md:h-[294px] w-full h-full rounded-md xl:mr-10"
            alt="" />
        </div>
      </div>
      <div class=" md:mt-0 w-full max-w-[1280px] py-4 lg:p-0 xl:p-0 p-3 xl:px-4 ">
        <div class="w-full mt-[20rem] md:mt-0 lg:columns-3 xl:columns-3 md:columns-2 columns-1 space-y-4 py-4">
          <div v-for="products in dataitem" :key="products" class="group relative w-full">

            <div
              class="absolute lg:block xl:block md:block hidden h-full w-full overflow-hidden rounded-md group-hover:bg-opacity-55">
              <div
                class="absolute  h-10 w-10 overflow-auto duration-300 transition-all group-hover:h-full group-hover:w-full group-hover:bg-white/50">
              </div>

              <div class="flex justify-center items-end w-full h-[80%] -mt-[50px] mb-10 ">
                <div
                  class="text-center text-2xl font-semibold text-black overflow-hidden opacity-0 group-hover:opacity-100">
                  <p class=" group-hover:translate-y-0 duration-300">
                    {{ products.name }}
                  </p>
                </div>
              </div>
              <router-link v-if="products && products.id" :to="{
    name: 'systemdetails',
    params: { id: products.id },
  }" class="flex items-end justify-center  opacity-0 group-hover:opacity-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor"
                  class="h-12 w-12 cursor-pointer -mt-5 text-black opacity-0 duration-300 group-hover:translate-y-[0px] group-hover:opacity-100">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </router-link>
            </div>
            <router-link v-if="products && products.id" :to="{
    name: 'systemdetails',
    params: { id: products.id },
  }" class="absolute lg:hidden xl:hidden md:hidden  group py-3 group-active:bg-primery1/80 cursor-pointer bg-primery1 bottom-0 w-full bg-opacity-50 rounded-bl-[6px] rounded-br-[6px] flex items-center justify-end">
              <div class="w-full relative">
                <h2 class="text-body text-center text-background">
                  {{ products.name }}
                </h2>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-circle-chevron-right w-8 h-8 text-white absolute top-0 right-3 group-hover:translate-x-2 duration-300">
                  <circle cx="12" cy="12" r="10" />
                  <path d="m10 8 4 4-4 4" />
                </svg>

              </div>
            </router-link>
            <!-- <div class="w-full h-full">
              <img class="w-full rounded-md" :src="products.image" />
            </div> -->
            <div class="w-full h-full bg-primery1/5 rounded-md">
                <img class="w-full object-scale-down rounded-md   " :src="products.image" />
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-[48px]"></div>
  </div>
</template>
<script>
import { getCollectionQuery } from "@/composible/getCollection";
import { ref, onMounted } from "vue";
export default {
  setup() {
    const dataitem = ref([]);
    const getData = async () => {
      try {
        await getCollectionQuery(
          "products",
          [],
          (data) => {
            dataitem.value = data.filter(
              (item) => item.type === "System Development"
            );
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    const category = ref([]);

    const getDataCategory = async () => {
      try {
        await getCollectionQuery(
          "categories",
          [],
          (data) => {
            category.value = data.filter(
              (item) => item.name === "System Development"
            );
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };
    onMounted(() => {
      getData();
      getDataCategory();
    });
    return { dataitem, category };
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
</style>
