<template>
  <!-- <Navbar /> -->


  <div class="bg-gray-100">
    <div class="grid grid-cols-6 md:grid-cols-9 lg:grid-cols-6 xl:grid-cols-7 h-screen">
      <div class="bg-white/50 my-4">
        <div v-if="userDocument">
          <div class="mt-5">
            <div class="flex flex-wrap space-x-2 items-center justify-center p-2">
              <img class="w-12 h-12" src="../assets/image/image 5.png" alt="" />
              <div class="font-bold text-sm md:text-md lg:text-lg text-center ">Master IT System</div>
            </div>
          </div>
          <div class="p-3 space-y-1">
            <div class="">
              <router-link to="/admin"
                class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold justify-center lg:pl-2 py-[12px] lg:justify-normal  hover:text-indigo-300   duration-300">

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-circle-gauge">
                  <path d="M15.6 2.7a10 10 0 1 0 5.7 5.7" />
                  <circle cx="12" cy="12" r="2" />
                  <path d="M13.4 10.6 19 5" />
                </svg>
                <p class="hidden lg:flex">ទំព័រដើម</p>
              </router-link>
            </div>
            <div>

              <router-link
                class="text-body flex space-x-2  text-primery1 font-semibold justify-center lg:pl-2 py-[12px] lg:justify-normal  hover:text-indigo-300   duration-300"
                to="/services">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-boxes">
                  <path
                    d="M2.97 12.92A2 2 0 0 0 2 14.63v3.24a2 2 0 0 0 .97 1.71l3 1.8a2 2 0 0 0 2.06 0L12 19v-5.5l-5-3-4.03 2.42Z" />
                  <path d="m7 16.5-4.74-2.85" />
                  <path d="m7 16.5 5-3" />
                  <path d="M7 16.5v5.17" />
                  <path
                    d="M12 13.5V19l3.97 2.38a2 2 0 0 0 2.06 0l3-1.8a2 2 0 0 0 .97-1.71v-3.24a2 2 0 0 0-.97-1.71L17 10.5l-5 3Z" />
                  <path d="m17 16.5-5-3" />
                  <path d="m17 16.5 4.74-2.85" />
                  <path d="M17 16.5v5.17" />
                  <path
                    d="M7.97 4.42A2 2 0 0 0 7 6.13v4.37l5 3 5-3V6.13a2 2 0 0 0-.97-1.71l-3-1.8a2 2 0 0 0-2.06 0l-3 1.8Z" />
                  <path d="M12 8 7.26 5.15" />
                  <path d="m12 8 4.74-2.85" />
                  <path d="M12 13.5V8" />
                </svg>
                <p class="hidden lg:flex">ប្រភេទសេវាកម្ម</p>
              </router-link>
            </div>

            <!-- <div>
              <router-link
                class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold justify-center lg:pl-2  py-[12px] lg:justify-normal  hover:text-indigo-300   duration-300"
                to="/products">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-archive">
                  <rect width="20" height="5" x="2" y="3" rx="1" />
                  <path d="M4 8v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8" />
                  <path d="M10 12h4" />
                </svg>
                <p class="hidden lg:flex">ផលិតផល</p>
              </router-link>
            </div> -->

            <div>
              <router-link
                class="text-body flex space-x-2 text-nowrap  text-primery1 font-semibold justify-center lg:pl-2  py-[12px] lg:justify-normal  hover:text-indigo-300   duration-300"
                to="/listproduct">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                </svg>

                <p class="hidden lg:flex">ឧបករណ៍</p>
              </router-link>
            </div>

            <div v-if="userDocument.role == 'SuperAdmin'">
              <router-link
                class="text-body flex space-x-2 text-nowrap text-primery1 font-semibold justify-center lg:pl-2  py-[12px] lg:justify-normal  hover:text-indigo-300   duration-300"
                to="/adminmanager">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-users">
                  <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                  <circle cx="9" cy="7" r="4" />
                  <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                  <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                </svg>
                <p class="hidden lg:flex">អ្នកប្រើប្រាស់</p>
              </router-link>
            </div>

            <div>
              <router-link
                class="text-body flex space-x-2 text-nowrap text-primery1 font-semibold justify-center lg:pl-2  py-[12px] lg:justify-normal  hover:text-indigo-300   duration-300"
                to="/qanda">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-circle-help">
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <path d="M12 17h.01" />
                </svg>
                <p class="hidden lg:flex">សំណួរខ្លី</p>
              </router-link>
            </div>
            <div>
              <router-link
                class="text-body flex space-x-2 text-nowrap text-primery1 font-semibold justify-center lg:pl-2  py-[12px] lg:justify-normal  hover:text-indigo-300   duration-300"
                to="/partner">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-handshake">
                  <path d="m11 17 2 2a1 1 0 1 0 3-3" />
                  <path
                    d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4" />
                  <path d="m21 3 1 11h-2" />
                  <path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3" />
                  <path d="M3 4h8" />
                </svg>
                <p class="hidden lg:flex">ដៃគូសហការណ៍</p>
              </router-link>
            </div>
            <div>
              <router-link
                class="text-body flex space-x-2 text-nowrap text-primery1 font-semibold justify-center lg:pl-2  py-[12px] lg:justify-normal  hover:text-indigo-300   duration-300"
                to="/inbox">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-package-open">
                  <path d="M12 22v-9" />
                  <path
                    d="M15.17 2.21a1.67 1.67 0 0 1 1.63 0L21 4.57a1.93 1.93 0 0 1 0 3.36L8.82 14.79a1.655 1.655 0 0 1-1.64 0L3 12.43a1.93 1.93 0 0 1 0-3.36z" />
                  <path
                    d="M20 13v3.87a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13" />
                  <path
                    d="M21 12.43a1.93 1.93 0 0 0 0-3.36L8.83 2.2a1.64 1.64 0 0 0-1.63 0L3 4.57a1.93 1.93 0 0 0 0 3.36l12.18 6.86a1.636 1.636 0 0 0 1.63 0z" />
                </svg>
                <p class="hidden lg:flex">ប្រអប់សារ</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-5 md:col-span-8 lg:col-span-5 xl:col-span-6 mx-4 my-4">
        <div class="mb-1">
          <NavbarProductDetail />
        </div>

        <div class=" font-NotoSansKhmer h-[800px] lg:h-[860px] overflow-y-auto w-full">

          <div class="lg:flex gap-5 w-full ">
            <div
              class="md:w-full lg:w-[70%] bg-white/50 xl:w-[50%]   p-5 space-y-4 pt-3 overflow-hidden overflow-y-auto xl:mb-0  mt-5">
              <div class="">
                <div class="flex items-center w-full ">
                  <div class="w-full">
                    <h2 class="text-heading2 text-primery1 font-semibold my-5">រូបភាព:</h2>
                    <img :src="product.image"
                      class="w-full md:w-[100%] lg:w-[100%] xl:w-[100%] h-[400px] border-double border-4  rounded-md border-indigo-500 object-cover"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-white/50 mt-5 p-5 md:w-full lg:w-[70%] xl:w-[50%]">
              <div>
                <div class="flex flex-wrap items-center w-full ">
                  <div class="">
                    <h2 class="text-heading2 text-primery1 font-semibold mt-2">ឈ្មោះផលិផល:</h2>
                    <h2 class="text-body text-textbody font-bold">{{ product.name }}</h2>
                  </div>
                </div>
                <div class="flex items-center mt-2 my-5">
                  <div class="w-full md:w-full lg:w-[100%] xl:w-[100%]">
                    <h2 class="text-heading2 text-primery1 font-semibold">លំអិត:</h2>
                    <h2 class="text-body text-textbody line-clamp-4 text-md " v-html=" product.descritpts"></h2>
                  </div>
                </div>
                <div class="w-full border-t-2">
                  <h2 class="text-primery1 text-heading4 mt-3 font-bold">លក្ខណរបស់ផលិត:</h2>

                </div>
                <div>
                  <div class="grid grid-cols-1 space-y-2">
                    <div v-for="features in product.feature" :key="features" class="w-full space-y-4">
                      <div class="w-full flex">
                        <div class="w-1/2">
                          <h2 class="text-body text-textbody font-semibold">ជំណងជើង:</h2>
                        </div>
                        <div class="w-1/2">
                          <h2 class="text-body text-textbody">
                            {{ features.title }}
                          </h2>
                        </div>
                      </div>
                      <div class="w-full flex">
                        <div class="w-1/2">
                          <h2 class="text-body text-textbody font-semibold">លំអិត:</h2>
                        </div>
                        <div class="w-1/2">
                          <h2 v-html="features.details" class="prose text-body text-textbody">
                            
                          </h2>
                        </div>
                      </div>
                      <div class="w-full">
                        <div class="w-1/2">
                          <h2 class="text-body text-textbody font-semibold">រូបភាព:</h2>
                        </div>
                        <div class="w-1/2">
                          <img class="w-full h-[200px] object-cover rounded-md" :src="features.image" alt="" />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

  <!-- <Footer /> -->
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { projectFirestore } from "@/firebase/config";
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Navbar from "./Navbar.vue";
import Footer from "./Footer.vue";
import NavbarProductDetail from '@/titleAdmin/NavbarProductDetail.vue';
import getUser from '@/composible/getUser'

export default {
  components: {
    Navbar,
    Footer,
    NavbarProductDetail
  },
  props: {
    id: String, // Accept product ID as a prop
  },
  setup(props) {
    const product = ref(true);

    const fetchProduct = async () => {
      try {
        const productRef = doc(projectFirestore, "products", props.id);
        const docSnap = await getDoc(productRef);
        if (docSnap.exists()) {
          product.value = docSnap.data();
        } else {
          console.error(`Product with ID ${props.id} not found.`);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };



    const userDocument = ref(null);

    const fetchUserData = async () => {
      try {
        const userlogin = getAuth().currentUser;
        if (userlogin) {
          // Check if user data is already fetched
          if (!userDocument.value) {
            const docRef = doc(projectFirestore, "users", userlogin.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              // Store user data in a persistent state
              userDocument.value = docSnap.data();
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    onMounted(() => {
      fetchProduct();
      fetchUserData();
    });

    // Cleanup logic if needed

    return { product, userDocument };
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #510192b9;
  border-radius: 10px;
  margin-left: 20px;
  transition: 3s;
}

::-webkit-scrollbar-thumb:hover {
  display: block;
  transition: 0.5s;
}
</style>
