<template>
    <div class="mx-auto lg:w-[90%] xl:w-[1280px] md:w-full w-full   p-4 mb-10 md:mb-[30px] lg:mb-[20px] xl:mb-[60px]">

        <div
            class="hidden md:justify-center lg:gap-10 xl:gap-[50px] lg:flex xl:flex   items-start justify-between py-4">
            <div class="lg:space-y-[12px] xl:space-y-[12px]">
                <h2 class="lg:text-[24px] xl:text-[28px] text-[20px] font-bold">សំណួរដែលគេសួរញឹកញាប់</h2>
                <div>
                    <p class="lg:text-[14px] xl:text-[18px]">សំណួរដែលអ្នកអាចសួរអំពីផលិតផល និងសេវាកម្មរបស់យើង។
                    </p>
                </div>
            </div>

            <!-- Accordion Item -->
            <div
                class=" grid grid-cols-1 gap-y-4 font-NotoSansKhmer  lg:w-[490px] lg:text-body xl:text-body text-textbody lg:h-auto xl:w-[823px] xl:h-auto md:w-[400px] md:h-auto w-full rounded-[6px] duration-300 text-body">
                <div class="relative mb-3" v-for="(doc, index) in documents" :key="index">
                    <hr class="h-[2px] bg-[#E6E6E6]">
                    <h6 class="mb-0 my-8">
                        <button @click="toggle(index)" :aria-expanded="isOpen(index)"
                            :aria-controls="'content-' + index"
                            class="relative flex items-center w-full  font-semibold text-left transition-all ease-in border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group ">
                            <span class="text-[20px] lg:text-[18px] xl:text-[20px] font-bold ">{{ doc.question }}</span>
                            <i class="absolute right-0 pt-1 text-xs fa"
                                :class="{ 'fa-plus': !isOpen(index), 'fa-minus': isOpen(index) }"></i>
                        </button>
                    </h6>
                    <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                        <div v-show="isOpen(index)" :id="'content-' + index"
                            class="overflow-hidden transition-all duration-300 ease-in-out">
                            <div class="lg:text-[16px] mt-3 xl:text-body md:text-body text-body_Mobile text-[#6b6b6b] ">
                                {{ doc.answer }}
                            </div>
                        </div>
                    </transition>
                    <div class="absolute top-0 right-0 my-8">
                        <svg @click="toggle(index)"
                            :class="isOpen(index) ? 'icon-transition icon-open' : 'icon-transition icon-closed'"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-7  cursor-pointer ">
                            <path v-if="!isOpen(index)" stroke-linecap="round" class="font-bold" stroke-linejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15" />
                            <path v-else stroke-linecap="round" class="font-bold" stroke-linejoin="round"
                                d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>


        <!-- mobile size -->

        <div class="flex justify-center ">
            <div class="lg:hidden    mx-auto w-[95%] md:w-[80%] ">
                <div class="space-y-[10px] text-center">
                    <h2 class="md:text-[28px] font-bold text-[20px]">សំណួរដែលគេសួរញឹកញាប់</h2>
                    <div>
                        <p class="text-[14px] md:text-[18px]">សំណួរដែលអ្នកអាចសួរអំពីផលិតផល និងសេវាកម្មរបស់យើង។
                        </p>
                    </div>
                </div>

                <!-- Accordion Item -->
                <div
                    class="mt-[24px] grid grid-cols-1 gap-y-4 font-NotoSansKhmer lg:text-body xl:text-body text-textbody xl:h-auto md:w-full md:h-auto w-full rounded-[6px] duration-300 text-body">
                    <div class="relative md:mb-3" v-for="(doc, index) in documents" :key="index">
                        <hr class="h-[2px] bg-[#E6E6E6]">
                        <h6 class="mb-0 my-4 md:mt-5">
                            <button @click="toggle(index)" :aria-expanded="isOpen(index)"
                                :aria-controls="'content-' + index"
                                class="relative flex items-center w-full  font-semibold text-left transition-all ease-in border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group ">
                                <span class="md:text-[20px] text-[18px]  font-bold ">{{ doc.question }}</span>
                                <i class="absolute right-0 pt-1 text-xs fa"
                                    :class="{ 'fa-plus': !isOpen(index), 'fa-minus': isOpen(index) }"></i>
                            </button>
                        </h6>
                        <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                            <div v-show="isOpen(index)" :id="'content-' + index"
                                class="overflow-hidden transition-all duration-300 ease-in-out">
                                <div class=" mt-3 xl:text-body md:text-body text-body_Mobile text-[#6b6b6b] ">
                                    {{ doc.answer }}
                                </div>
                            </div>
                        </transition>
                        <div class="absolute top-0 right-0 my-4 md:mt-5">
                            <svg @click="toggle(index)"
                                :class="isOpen(index) ? 'icon-transition icon-open' : 'icon-transition icon-closed'"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-7  cursor-pointer ">
                                <path v-if="!isOpen(index)" stroke-linecap="round" class="font-bold"
                                    stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                <path v-else stroke-linecap="round" class="font-bold" stroke-linejoin="round"
                                    d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getCollection from '@/composible/getCollections';

export default {
    data() {
        return {
            openItem: null,
            documents: []
        };
    },
    async created() {
        try {
            const { documents } = await getCollection("FAQ");
            this.documents = documents;
        } catch (error) {
            console.error("Failed to fetch documents:", error);

        }
    },
    methods: {
        
        toggle(index) {
            this.openItem = this.openItem === index ? null : index;
        },
        isOpen(index) {
            return this.openItem === index;
        },
        beforeEnter(el) {
            el.style.height = '0';
            el.style.opacity = '0';
        },
        enter(el) {
            el.offsetHeight;
            el.style.transition = 'height 0.3s ease-in-out, opacity 0.3s ease-in-out';
            el.style.height = `${el.scrollHeight}px`;
            el.style.opacity = '1';
        },
        leave(el) {
            el.style.transition = 'height 0.3s ease-in-out, opacity 0.3s ease-in-out';
            el.style.height = '0';
            el.style.opacity = '0';
        }
    }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s, height 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.icon-transition {
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.icon-closed {
    opacity: 1;
}

.icon-open {
    transform: rotateX(180deg);
    transform: all 0.3s ease-in-out;

}
</style>