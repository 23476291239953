<template>
  <div class="flex flex-col items-center justify-center w-full h-full fixed top-0 right-0 bg-black/20 z-[50]">
    <div v-motion-slide-top
      class="xl:w-[50%] lg:w-[50%] w-[90%]  bg-white  p-5  -mt-[10rem] md:-mt-[15rem] lg:-mt-[15rem] xl:-mt-[15rem]">
      <div class=" mx-auto flex items-center justify-between">
        <h1 class="text-primery1 text-heading3">
          {{ datatoedit ? "កែប្រែ" : "បន្ថែមដៃគូសហការណ៍ថ្មី" }}
        </h1>
        <button @click="handleClose"
          class="w-10 h-10 rounded-full focus:border-primery1 focus:border flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-8 h-8 text-primery1">
            <path
              d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
          </svg>
        </button>
      </div>
      <form @submit.prevent="handleSubmit" class="w-auto space-y-2 items-center justify-center">
        <div class="w-full flex items-center gap-2">
          <div class="w-full h-full space-y-2">
            <label class="text-body text-textbody"> ឈ្មោះក្រុមហ៊ុនដៃគូ:* </label>

            <input v-model="partnername" required type="text" class="w-full p-2 border-[1px] hover:border-primery1"
              placeholder="ឈ្មោះក្រុមហ៊ុនដៃគូ" />
          </div>
        </div>
        <div class="w-full h-full space-y-2">
          <label class="text-body text-textbody">Logo ក្រុមហ៊ុន:* </label>

          <div class="input border-dashed relative h-[174px] w-[174px] overflow-auto">
            <img v-if="images" :src="images" alt="Partner Logo" class="w-[174px] h-[174px] object-contain" />
            <div v-else class="text-center text-lebeltext text-heading4">
              <img :src="img || 'https://fakeimg.pl/174x174/'" class="w-[174px] h-[174px] object-contain" alt="Default Logo" />
            </div>
            <input type="file" @change="handleFileChange" class="opacity-0 absolute w-full h-full" placeholder="Logo" />
          </div>
        </div>


        <div class="w-full space-y-2">
          <label class="text-body text-textbody"> លម្អិតអំពីក្រុមហ៊ុន </label>
          <textarea placeholder="លំអិត" class="border-[1px] hover:border-primery1 p-2 w-full" cols="10" rows="5"
            v-model="partnerDetails"></textarea>
        </div>

        <div v-if="loading == false" class="w-auto flex justify-end mx-auto">
          <button type="submit"
            class="px-10 p-3 bg-primery1 hover:bg-blue-500 transition-all duration-300 ease-in-out text-white">
            {{ datatoedit ? "កែប្រែ" : "បន្ថែមថ្មី" }}
          </button>
        </div>
        <div v-else class="w-auto flex justify-end mx-auto">
          <button class="px-4 p-3 bg-primery1 flex items-center justify-center text-white">
            <svg aria-hidden="true" role="status" class=" w-6 h-6 me-3 text-white animate-spin" viewBox="0 0 100 101"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor" />
            </svg>
            កំពុងបញ្ចូល
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import useCollection from "@/composible/useCollection";
import useStorage from "@/composible/useStorange";
import { timestamp } from "@/firebase/config";

export default {
  props: ["datatoedit"],
  emit: ["AddSusccesfully", "UpddateSuccess"],
  setup(props, { emit }) {
    const { addDocs, removeDoc, updateDocs } = useCollection("partner");
    const { uploadImage } = useStorage();

    const images = ref("")


    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (!file) {
        console.error("No file selected.");
        return;
      }
      const allowedExtensions = ["jpg", "png", "svg", "jpeg"];
      const extension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(extension)) {
        console.error("Only jpg, png, svg, and jpeg files are allowed.");
        return;
      }
      img.value = file;
      images.value = URL.createObjectURL(file)
    };



    const handleClose = () => {
      emit("close");
    };
    const partnername = ref("");
    const img = ref("");
    const partnerDetails = ref("");
    const loading = ref(false);
    const handleSubmit = async () => {
      loading.value = true;

      try {
        let imageURL = null;

        if (img.value && img.value !== props.datatoedit?.image) {
          if (img.value.size > 1024 * 1024) {
            console.error("Image size exceeds 1MB limit.");
            return;
          }
          const storagePath = `categories/${img.value.name}`;
          imageURL = await uploadImage(storagePath, img.value);
          
        } else {
          // If img.value hasn't changed or is not provided, retain the existing image URL
          imageURL = props.datatoedit?.image;
        }

        const productData = {
          name: partnername.value,
          description: partnerDetails.value,
          image: imageURL,
          createdAt: timestamp(),
        };

        if (props.datatoedit) {
          const upadateSuccess = await updateDocs(
            props.datatoedit?.id,
            productData
          );
          console.log("====================================");
          console.log(productData);
          console.log("====================================");
          if (upadateSuccess) {
            emit("handleUpdateSuccess");
          }
        } else {
          const success = await addDocs(productData);
          if (success) {
            emit("AddSusccesfully");
          }
        }
        console.log("Product operation successful");
      } catch (error) {
        console.error("Error performing product operation:", error);
      }
      handleClear();
      handleClose();
    };
    onMounted(() => {
      if (props.datatoedit) {
        partnername.value = props.datatoedit.name;
        partnerDetails.value = props.datatoedit.description;
        img.value = props.datatoedit.image;
      }
    });
    const handleClear = () => {
      partnername.value = "";
      partnerDetails.value = "";
      img.value = null;
    };
    return {
      handleClose,
      partnername,
      partnerDetails,
      handleFileChange,
      handleSubmit,
      img,
      loading,
      images
    };
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #510192b9;
  border-radius: 10px;
  margin-left: 20px;
  transition: 3s;
}

::-webkit-scrollbar-thumb:hover {
  display: block;
  transition: 0.5s;
}
</style>
