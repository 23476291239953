<template>
    <div v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,
            ease: 'easeIn',
        },
    }" class="flex flex-col bg-background px-2 md:px-0 ">
        <div class="w-full  md:w-[90%] xl:w-[1280px] lg:w-[90%] mx-auto space-y-2 ">
            <div class="lg:mt-8 xl:mt-8  my-4 text-start lg:p-0 xl:p-0 p-3 md:p-3 xl:px-4">
                <h2
                    class="text-[20px] md:text-start md:text-[30px] lg:text-[22px] font-NotoSansKhmer text-primery1 lg:text-start xl:text-start text-center xl:ml-0 xl:text-[30px] font-semibold">
                    រចនាគេហទំព័រ
                </h2>
            </div>

            <div
                class="xl:px-4 w-full lg:w-[100%] xl:w-[1280px] py-4 lg:p-0 xl:p-0 p-3 md:p-3 mx-auto border-b-gray-400 lg:h-auto xl:auto md:h-auto h-auto lg:flex lg:flex-row xl:flex xl:flex-row md:flex md:flex-col-reverse flex flex-col-reverse items-center justify-between">
                <div v-for="categories in category" :key="categories"
                    class=" text-[14px] font-[500] text-textbody  xl:my-3 -mt-8 md:-mt-4 lg:-mt-0 md:text-[18px]  lg:text-[14px] xl:text-[18px] xl:indent-16">
                    <!-- {{ categories.description }} -->
                    <!-- <p class="" v-html="categories.description"></p> -->
                    <span
                        class="prose 	 prose-li:indent-0 text-[14px] font-[500] text-textbody   -mt-8 md:-mt-4 lg:-mt-0 md:text-[18px]  lg:text-[14px] xl:text-[18px] xl:indent-16"
                        v-html="categories.description"></span>
                </div>
            </div>


            <div class="w-full max-w-[1280px] py-4 lg:p-0 xl:p-0 p-3 md:p-3 xl:px-4">
                <div class="w-full lg:columns-3 xl:columns-3 md:columns-2 columns-1 space-y-4 py-4">
                    <div v-for="products in dataitem" :key="products" class="group relative w-full">
                        <div
                            class="absolute lg:block xl:block md:block hidden h-full w-full overflow-hidden rounded-md group-hover:bg-opacity-55">
                            <div
                                class="absolute top-0 h-10 w-10 overflow-auto bg-opacity-0 duration-300 group-hover:h-full group-hover:w-full group-hover:bg-white/50">
                            </div>

                            <div class="flex justify-center items-end w-full h-[70%] -mt-[50px]">
                                <div
                                    class="text-center text-2xl font-semibold text-black overflow-hidden opacity-0 group-hover:opacity-100">
                                    <p class=" group-hover:translate-y-0 duration-300">
                                        {{ products.name }}
                                    </p>
                                </div>
                            </div>
                            <router-link v-if="products && products.id" :to="{
        name: 'systemdetails',
        params: { id: products.id },
    }" class="flex items-end justify-center group-hover:h-[100px] opacity-0 group-hover:opacity-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor"
                                    class="h-12 w-12 cursor-pointer text-black opacity-0  duration-300 group-hover:translate-y-[0px] group-hover:opacity-100">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </router-link>
                        </div>
                        <router-link v-if="products && products.id" :to="{
        name: 'systemdetails',
        params: { id: products.id },
    }" class="absolute lg:hidden xl:hidden md:hidden group py-3 group-active:bg-primery1/80 cursor-pointer bg-primery1 bottom-0 w-full bg-opacity-50 rounded-bl-[6px] rounded-br-[6px] flex items-center justify-end">
                            <div class="w-full relative">
                                <h2 class="text-body text-center text-background">
                                    {{ products.name }}
                                </h2>
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor"
                                    class="w-8 h-8 text-white absolute top-0 right-3 group-hover:translate-x-2 duration-300">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg> -->

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="lucide lucide-circle-chevron-right w-8 h-8 text-white absolute top-0 right-3 group-hover:translate-x-2 duration-300">
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="m10 8 4 4-4 4" />
                                </svg>
                            </div>
                        </router-link>
                        <!-- <div class="w-full h-full" style="background-color: rgba(151, 71, 255, 1);"> -->
                        <div class="w-full h-full bg-primery1/5 rounded-md">
                            <img class="w-full object-scale-down rounded-md " :src="products.image" />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h1
                    class="text-center text-[16px]  -mt-4 md:mt-0 mb-4 md:mb-0 md:text-[24px] lg:text-[20px] xl:text-[24px] ">
                    តើអាជីវកម្មរបស់អ្នកត្រូវនឹងគេហទំព័រប្រភេទណា?</h1>
                <div class="flex justify-center mt-2">
                    <img src="../assets/image/image.png"
                        class=" w-[398px] h-[245px]  md:w-full md:h-full lg:w-[1092px] lg:h-[716px] xl:w-[1092px]  xl:px-4"
                        alt="">
                </div>
            </div>

            <div>
                <div
                    class="w-full  lg:w-[100%] xl:w-[90%] lg:mt-5 xl:mt-0 py-4 lg:p-0 xl:p-0 p-3 md:p-3 mx-auto border-b-gray-400 lg:h-auto xl:auto md:h-auto h-auto lg:flex lg:flex-row xl:flex xl:flex-row md:flex md:flex-col-reverse flex flex-col-reverse items-center justify-between">
                    <p v-for="categories in category" :key="categories"
                        class="text-[14px] text-textbody my-3 xl:my-5   lg:-mt-0 md:text-[18px]  lg:text-[14px] xl:text-[18px] xl:indent-16 font-[500]">
                        <!-- {{ categories.categoryDescription1 }} -->
                    </p>
                </div>
                <div class="flex justify-center mt-2">
                    <img src="../assets/image/7_phrash1.png"
                        class="rounded-[6px] w-[398px] h-[245px] md:w-full md:h-full lg:w-[1092px] lg:h-[716px] xl:w-[1092px] xl:px-4 object-contain xl:h-[770px]"
                        alt="">
                </div>
            </div>

        </div>
        <div class="w-full h-[48px]"></div>
    </div>
</template>
<script>
import { getCollectionQuery } from "@/composible/getCollection";
import { ref, onMounted } from "vue";
export default {
    setup() {
        const dataitem = ref([]);
        const getData = async () => {
            try {
                await getCollectionQuery(
                    "products",
                    [],
                    (data) => {
                        dataitem.value = data.filter(
                            (item) => item.type === "Web Design"
                        );
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };
        const category = ref([]);

        const getDataCategory = async () => {
            try {
                await getCollectionQuery(
                    "categories",
                    [],
                    (data) => {
                        category.value = data.filter(
                            (item) => item.name === "Web Design"
                        );
                    },
                    true
                );
            } catch (error) {
                console.error(error.message);
            }
        };
        onMounted(() => {
            getData();
            getDataCategory();
        });
        return { dataitem, category };
    },
};
</script>
<style scoped>
::-webkit-scrollbar {
    display: none;
}
</style>