<template>
    <div class="hidden sm:block">
        <h1>Hello</h1>
    </div>

    <div v-if="products && products.length > 0"
        class="relative rounded-[6px] sm:hidden grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mb-2 m-[16px] "
        ref="carouselContainer" @scroll="handleScroll">
        <!-- Previous Button -->
        <div @click="handlePrevious" :class="{ hideButton: start === 0 }"
            class="absolute top-1/2 -left-2 z-50 transform -translate-y-1/2">
            <div
                class="w-[40px] h-[40px] rounded-full lg:hidden bg-[#500192]/30 shadow-md text-white flex hover:bg-indigo-300 justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
            </div>
        </div>
        <!-- Next Button -->
        <div v-if="windowWidth < 640" @click="handleNext" :class="{ hideButton: end >= products.length - 1 }"
            class="absolute top-1/2 -right-2 z-10 transform -translate-y-1/2">
            <div
                class="w-[40px] h-[40px] rounded-full bg-[#500192]/30 shadow-md text-white flex hover:bg-indigo-300 justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
            </div>
        </div>
        <!-- Products -->
        <Transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <div v-show="currentIndex >= start && currentIndex <= end" :key="currentIndex"
                class="relative transform transition-transform duration-300 flex justify-center">
                <div v-for="(product, index) in products" :key="product.id" v-show="index === currentIndex">
                    <img :src="product.image" class="object-contain w-[360px] h-[220px] rounded-[6px] duration-1000"
                        :style="{ opacity: 1 }" />
                    <div class="text-center space-y-[22px]">
                        <h1 class="text-[18px] mt-[22px]">{{ product.name }}</h1>
                        <p class="text-[14px] text-justify">{{ product.descritpts }}</p>
                    </div>
                </div>
            </div>
        </Transition>
        <!-- Indicators -->
        <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            <span v-for="(product, index) in products" :key="product.id"
                :class="['w-3 h-3 rounded-full', { 'bg-blue-500': index === currentIndex, 'bg-gray-300 ': index !== currentIndex }]"
                @click="goToSlide(index)" class="cursor-pointer"></span>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, watchEffect, onUnmounted } from 'vue';
import getCollections from "@/composible/getCollections";

export default {
    setup() {
        const start = ref(0);
        const end = ref(1);
        const windowWidth = ref(window.innerWidth);
        const currentIndex = ref(0);
        const carouselContainer = ref(null);
        const increment = ref(1);
        const decrement = ref(1);

        const { error, documents: products } = getCollections("products");
        if (!products.value) products.value = [];

        const onResize = () => {
            windowWidth.value = window.innerWidth;
            updateEnd();
            if (currentIndex.value < start.value || currentIndex.value > end.value) {
                currentIndex.value = start.value;
            }
            updateScroll();
        };

        onMounted(() => {
            window.addEventListener('resize', onResize);
            updateEnd();
            if (carouselContainer.value) {
                carouselContainer.value.addEventListener('scroll', handleScroll);
            }
        });

        onUnmounted(() => {
            if (carouselContainer.value) {
                carouselContainer.value.removeEventListener('scroll', handleScroll);
            }
            window.removeEventListener('resize', onResize);
        });

        const updateEnd = () => {
            if (windowWidth.value < 640) {
                end.value = start.value;
            } else if (windowWidth.value < 768) {
                end.value = start.value + 1;
            } else if (windowWidth.value < 1024) {
                end.value = start.value + 2;
            } else if (windowWidth.value < 1280) {
                end.value = start.value + 3;
            } else {
                end.value = start.value + 4;
            }
        };

        watchEffect(updateEnd);

        const handleNext = () => {
            if (end.value < products.value.length - 1) {
                start.value += increment.value;
                end.value += increment.value;
                currentIndex.value = end.value;
                updateScroll();
            }
        };

        const handlePrevious = () => {
            if (start.value > 0) {
                start.value -= decrement.value;
                end.value -= decrement.value;
                currentIndex.value = start.value;
                updateScroll();
            }
        };

        const updateScroll = () => {
            if (carouselContainer.value) {
                carouselContainer.value.scrollTo({
                    left: carouselContainer.value.scrollWidth * (currentIndex.value / products.value.length),
                    behavior: 'smooth'
                });
            }
        };

        const handleScroll = () => {
            // Implement your scroll logic here if needed
        };

        const beforeEnter = (el) => {
            el.style.opacity = 0;
        };

        const enter = (el, done) => {
            el.offsetHeight;
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 1;
            done();
        };

        const leave = (el, done) => {
            el.style.transition = 'opacity 0.5s';
            el.style.opacity = 0;
            done();
        };

        const goToSlide = (index) => {
            currentIndex.value = index;
            start.value = index;
            end.value = index + (windowWidth.value < 640 ? 0 : 1);
            updateScroll();
        };

        return { error, products, start, end, windowWidth, handleNext, handlePrevious, currentIndex, beforeEnter, enter, leave, carouselContainer, goToSlide };
    },
};
</script>
<style>
.hideButton {
    display: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
}

.indicator.active {
    background-color: #007bff;
}
</style>
