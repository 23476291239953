<template>
    <div class="bg-primery2/40 h-[220px] md:h-[365px] xl:h-[428px] relative my-5">
        <div class="md:w-[95%] xl:w-[1280px] mx-auto">
            <div class="absolute top-4 xl:top-6 left-1/2 transform -translate-x-1/2">
                <h1 class="text-center text-white text-[20px] lg:text-[24px] xl:text-[28px] md:text-[28px]">ដៃគូរបស់យើង</h1>
            </div>
            <div class="flex justify-center items-center h-[220px] md:h-[365px] xl:h-[428px] w-full">
                <swiper :autoplay="{
                    delay: 1500,
                    disableOnInteraction: false
                }" :breakpoints="{
                    '640': {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    '768': {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    '1024': {
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                    '1280': {
                        slidesPerView: 4,
                        spaceBetween: 50
                    }
                }" :loop="true" :navigation="true" :modules="modules"
                    class="mySwiper flex justify-center xl:w-[1320px] md:px-[3rem] mt-[24px] md:mt-0">
                    <swiper-slide v-for="slide in slides" :key="slide.id">
                        <!-- <img :src="slide.image" class="ml-[10rem] md:ml-[2rem] md:w-[122px] lg:w-[120px] lg:h-[120px] md:h-[120px] xl:ml-0 w-[113px] h-[120px] slide-image xl:w-[172px] xl:h-[172px] object-contain" alt=""> -->
                        <div class="flex justify-center">
                            <img :src="slide.image" class="  md:w-[122px] lg:w-[120px] lg:h-[120px] md:h-[120px] xl:ml-0 w-[113px] h-[120px] slide-image xl:w-[172px] xl:h-[172px] object-contain" alt="">
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import getCollection from '@/composible/getCollections';

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        const { documents: slides } = getCollection('partner');

        return {
            modules: [Pagination, Navigation, Autoplay],
            slides
        };
    }
};
</script>

<style>

</style>
