<template>
    <div class="bg-[#fcfcfc]" 
    
    v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,

            ease: 'easeIn',
        },
    }"
    
    >
        <div class="md:w-[95%] lg:w-[90%] xl:w-[1280px] mx-auto pb-[16px] lg:pb-[60px] xl:px-4">
            <div class="px-5 xl:px-0 lg:mx-auto ">
                <h1 class="text-[24px] md:text-[35px] xl:text-[35px] lg:text-[30px] py-[16px] xl:py-[20px] text-center text-primery1">ឧបករណ៍</h1>
                <p class="text-[14px] font-[500] md:text-[18px]  xl:mx-0 lg:text-[16px] xl:text-[18px] xl:mb-10">
                    ឈុតឧបករណ៍ ប្រើក្នុងឈុតប្រព័ន្ធគ្រប់គ្រងអាជីវកម្ម POS Hardware បែបទំនើបស្តងដារ​ ទាន់សម័យ ងាយស្រួលប្រើប្រាស់  ទៅតាមតម្រូវការដែលអតិថិជនចង់បាន
                    
                    </p>
            </div>


            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-[16px] md:gap-[30px]  lg:gap-[30px] xl:gap-[40px] p-5 xl:p-0 md:mt-3 lg:mt-5">
                <div v-for="pro in product" :key="pro">
                    <div style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;"
                        class=" bg-white shadow- rounded-[6px]  mx-auto xl:w-auto h-[450px] md:h-[500px] lg:h-[500px] xl:h-[500px]  ">
                        <div class="flex justify-center">
                            <div>
                                <img :src="pro.url"
                                    class="w-[120px] h-[120px] md:w-[200px]  md:h-[200px] lg:w-[200px] lg:h-[200px] xl:w-[200px] xl:h-[200px] mt-[18px] object-contain"
                                    alt="">

                            </div>
                        </div>
                        <div class="mx-[20px] md:mx-[20px] ">
                            <!-- <div class="text-center">
                                <h1 class="text-[14px] md:text-[20px] lg:text-[18px] xl:text-[20px] lg:my-0 xl:my-2">{{ pro.productName }}</h1>
                            </div> -->

                            <div class="text-center">
                                <p class="text-[18px] line-clamp-1 font-bold md:text-[20px] lg:text-[18px] xl:text-[20px] lg:my-0 xl:my-2  ">{{ pro.productName }}</p>
                            </div>

                            <div v-html="pro.productDesscript " class="prose text-[#6B6B6B] font-[500] text-[14px] md:text-[16px] lg:text-[14px] xl:text-[16px space-y-1 px-2 mt-2 mb:mt-0">
                               
                            </div>


                            <!-- <div class="text-[14px] md:text-[16px] lg:text-[14px] space-y-1 px-2 mt-2 mb:mt-0">
                                <div v-if="pro.brand == ''" class="text-[#6B6B6B] xl:text-[16px]">

                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.brand }}
                                </li>


                                <div v-if="pro.description1 == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px] ">
                                    {{ pro.description1 }}
                                </li>


                                <div v-if="pro.description2 == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.description2 }}
                                </li>

                                <div v-if="pro.description3 == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.description3 }}
                                </li>


                                <div v-if="pro.description4 == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.description4 }}
                                </li>


                                <div v-if="pro.light_source == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.light_source }}
                                </li>
                               

                                <div v-if="pro.paper_type == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.paper_type }}
                                </li>
                               

                                <div v-if="pro.quantity == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.quantity }}
                                </li>
                               
                               
                                <div v-if="pro.speed == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.speed }}
                                </li>


                                <div v-if="pro.code_type
                                == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.code_type
                                    }}
                                </li>
                               
                               
                                <div v-if="pro.support_on == ''" class="text-[#6B6B6B] xl:text-[16px]">
                                </div>
                                <li v-else class="text-[#6B6B6B] xl:text-[16px]">
                                    {{ pro.support_on }}
                                </li>
                               
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- {{ product }} -->
</template>

<script>
import getCollection from '@/composible/getCollections'
export default {

    setup() {

        const { documents: product } = getCollection("newProduct")
        return { product }
    }
}
</script>

<style></style>