<template>
  <div v-motion :initial="{
    opacity: 0,
  }" :enter="{

    opacity: 1,
    transition: {
      duration: 500,
      ease: 'easeIn',
    },
  }" class="flex flex-col bg-background xl:py-4">
    <div class="xl:w-full md:w-full w-full h-auto xl:py-3 lg:mb-[32px] xl:mb-0">
      <div class="lg:w-full xl:w-[1280px] w-full md:w-full mx-auto">
        <div class="lg:mt-0 xl:-mt-8 md:my-[10px] text-center">
          <div class="lg:mt-5 xl:mt-0  text-center py-2">
            <h2
              class="text-[24px] my-2 md:text-[35px] xl:my-5 font-NotoSansKhmer  font-bold text-primery1  lg:text-[30px] xl:text-[35px]">
              ផលិតផល និងសេវាកម្មយើង
            </h2>
          </div>
          <div class="w-full">
            <div class="w-[100%] mx-auto md:w-[90%] md:px-3  lg:w-[990px] xl:w-full  lg:px-5 text-start">
              <p
                class="px-10 text-textbody md:p-0 font-[400] md:text-[20px] lg:text-body mb-3 xl:text-[20px]   font-NotoSansKhmer lg:w-[95%] mx-auto xl:w-[100%] w-full">
                ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម ផ្ដល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
                តាមរយៈការផ្គត់ផ្គង់ប្រព័ន្ធគ្រប់គ្រងសម្រាប់អាជីវកម្ម
                គ្រប់ប្រភេទរៀបចំ បង្កើតគេហទំព័រ និងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យថ្មី
                តាមតម្រូវការ។
              </p>
            </div>
          </div>
        </div>
      </div>


      <div>
        <!-- system -->
        <div
          class=" mb-5 mb:mb-20 lg:w-[90%] md:mt-[20px] lg:mx-auto xl:w-[1280px] w-[90%] md:w-[90%] h-auto mx-auto grid lg:grid-cols-3   xl:grid-cols-3 sm:grid-cols-2 md:gap-[30px] lg:gap-[32px] xl:gap-[40px] md:grid-cols-2 grid-cols-1 py-4 gap-y-4 xl:px-4">
          <div style=""  v-for="cart in category" :key="cart.id"
            class="p-10 h-[380px] shadow-meduim border md:border-0 md:p-[20px]  relative   bg-white hover:bg-[#fafafa]  transform group-hover:-translate-y-2   hover:shadow-meduim_hover  cursor-pointer md:h-[500px] w-auto group lg:h-[450px] xl:h-[600px]   rounded-md duration-300 mx-auto lg:p-[24px] xl:p-[30px]">
            <div class="relative w-full rounded-md rounded-b-none overflow-hidden flex justify-between items-center md:flex md:justify-between md:items-end gap-5">

              <div 
                class="lg:text-[20px] xl:text-[26px] md:text-[26px] text-heading2_Mobile font-bold group-hover:-translate-y-2 duration-300 text-primery1 lg:text-start xl:text-start text-start ">
                {{ cart.name }}
              </div>
              <img class="w-[100px] h-[100px] md:w-[100px] md:h-[100px] lg:w-[100px] lg:h-[100px]  xl:w-[100px] xl:h-[100px] object-cover group-hover:scale-105 duration-300" :src="cart.image"
                alt="Product Image" />

            </div>

            <div class="xl:mt-[50px] mt-2 lg:mt-[10px] w-full  rounded-md space-y-5 lg:space-y-0 xl:space-y-8 md:space-y-0">
              <div class="space-y-2  lg:space-y-0 mb-auto xl:space-y-0">
                <div
                  class="text-[#909090]  text-[15px] md:line-clamp-[10] lg:line-clamp-[11]   mt-[14px] md:mt-[10px] lg:text-[14px]  xl:text-body  md:text-body  group-hover:-translate-y-2 duration-300 font-[500]">
                  <!-- {{ truncateDescription(cart.description) }} -->
                  <span class="md:line-clamp-6 lg:line-clamp-8 line-clamp-4" v-html="cart.description"></span>
                </div>
              </div>
              <router-link :to="cart.route" class="md:absolute md:bottom-10 ">
                <button
                  class="lg:rounded-[6px] group-hover:text-white flex duration-150 mt-10 md:mt-0 items-center gap-3 border-[1px] xl:rounded-[6px] md:rounded-[6px] rounded-full lg:mx-0 xl:mx-0 mx-auto md:mx-0  lg:px-[28px] xl:px-[24px] transition-all shadow-sm px-[44px] py-[8px] md:px-[52px] md:py-[10px] text-xl text-gray-500 group-hover:bg-primery1/50 ease-in-out hover:text-white">
                  <span> View </span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 hidden group-hover:block group-hover:transition-all group-hover:duration-150 group-hover:ease-in-out group-hover:text-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                  </svg>
                </button>

              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { getCollectionQuery } from "@/composible/getCollection";
import { ref, onMounted } from "vue";
export default {
  setup() {
    const category = ref([]);
    const truncateDescription = (description) => {
      const words = description.split(" ");
      if (words.length > 50) {
        return words.slice(0, 15).join(" ") + " ...";
      } else {
        return description;
      }
    };
    const getData = async () => {
      try {
        await getCollectionQuery(
          "categories",
          [],
          (data) => {
            // Truncate the description of each category
            const truncatedData = data.map((item) => ({
              ...item,
              description: truncateDescription(item.description),
            }));
            category.value = truncatedData;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };

    onMounted(() => {
      getData();
    });

    const router = useRouter();

    return { category, truncateDescription };
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
